<!--
 * @Description: 企业认证选择认证方式页面
 * @Author: huacong
 * @Date: 2021-06-22 10:07:23
 * @LastEditTime: 2021-06-22 13:48:00
 * @LastEditors: huacong
-->
<template>
  <!-- 选择发布类型 -->
  <div class="contentBox">
    <section>
      <p class="text-center text-title">企业关联认证</p>
      <div class="btnBox">
        <div class="imgText clearfix" @click="goPostOne">
          <div>
            <img :src="iconleft" alt="" />
          </div>
          <div class="text">
            <span>已有企业</span><br />
            <span>公司信息已经存在，只需要选择已有公司信息进行认证</span>
          </div>
        </div>
        <div class="imgText clearfix" @click="goPostTwo">
          <div>
            <img :src="iconright" alt="" />
          </div>
          <div class="text">
            <span>新建企业</span><br />
            <span>公司信息首次在平台上认证，需要完善企业信息并认证</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      iconleft: require("@/assets/img/comreal01.png"),
      iconright: require("@/assets/img/comreal02.png"),
    };
  },
  methods: {
    goPostOne() {
      this.$router.push("haveComReal");
    },
    goPostTwo() {
      this.$router.push("comFirstLogin");
    },
  },
};
</script>
<style scoped>
.text-title {
  font-size: 28px;
  font-weight: bold;
  color: #2d59df;
  padding-top: 30px;
}
.btnBox {
  padding: 40px 100px 100px;
  text-align: center;
}
.btnBox .imgText:first-child {
  margin-right: 36px;
}
.imgText {
  width: 320px;
  padding: 20px 20px 15px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  display: inline-block;
}
.imgText:hover {
  background: #eef9fd;
  cursor: pointer;
}
.imgText:hover .text span:first-child {
  color: #2d59df;
}
.imgText div {
  float: left;
}
.imgText > div:first-child {
  width: 55px;
}
.imgText .text {
  width: 180px;
  padding-left: 80px;
  padding-top: 0px;
  line-height: 50px;
}
.padding-20 {
  padding: 20px;
}
.imgText img {
  width: 120px;
}
.imgText .text span:first-child {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  display: inline-block;
  padding: 9px;
  padding-top: 0;
}
.imgText .text span:last-child {
  font-size: 18px;
  color: #999999;
  line-height: 24px;
  display: inline-block;
  padding-left: 9px;
}
</style>
